/**
 * ! File: notification.ts
 * ! Author: Aditya Dedhia
 * ! Description: Module for displaying notification messages.
 *
 * Dependencies:
 * - Bootstrap Icons
 * - Tailwind CSS
 * - styles/modules/notification.css
 * - sendUserMessage URL access
 */
import { typedHTMX } from "./htmx";
const ENDPOINT_SEND_USER_MESSAGE = "/notify/<str:level>/<str:message>";
// Constants moved outside function
const MESSAGE_ICONS = {
    10: '<i class="bi bi-hammer"></i>', // Base Debug
    20: '<i class="bi bi-info-lg"></i>', // Base Info
    25: '<i class="bi bi-check-lg"></i>', // Base Success
    30: '<i class="bi bi-exclamation-triangle"></i>', // Base Warning
    40: '<i class="bi bi-x-octagon"></i>', // Base Error
    50: '<i class="bi bi-award"></i>' // Experience System
};
const MESSAGE_OPACITY = 0.75;
const MESSAGE_BACKGROUND_COLOR = {
    10: `background-color: rgba(240, 248, 255, ${MESSAGE_OPACITY})`,
    20: `background-color: rgba(157, 250, 255, ${MESSAGE_OPACITY})`,
    25: `background-color: rgba(137, 255, 171, ${MESSAGE_OPACITY})`,
    30: `background-color: rgba(255, 202, 110, ${MESSAGE_OPACITY})`,
    40: `background-color: rgba(255, 205, 210, ${MESSAGE_OPACITY})`,
    50: `background-color: rgba(255, 215, 0, ${MESSAGE_OPACITY})`
};
const TOAST_CLASSES = [
    "shadow-md", "text-white", "font-bold", "p-2", "fixed",
    "left-1/2", "transform", "-translate-x-1/2", "rounded-b-lg",
    "top-0", "z-50", "w-fit", "transition-all", "duration-500",
    "ease-in-out", "opacity-0", "backdrop-filter", "backdrop-blur-lg"
];
const MESSAGE_CREATION_INTERVAL_MS = 2500; // minimum time between release of messages
class MessageQueue {
    constructor() {
        this.queue = [];
        this.isProcessing = false;
        this.interval = MESSAGE_CREATION_INTERVAL_MS;
    }
    addMessage(message) {
        this.queue.push(message);
        if (!this.isProcessing) {
            this.processQueue();
        }
    }
    processQueue() {
        if (this.queue.length === 0) {
            this.isProcessing = false;
            return;
        }
        this.isProcessing = true;
        const message = this.queue.shift();
        if (message) {
            showMessageToast(message);
            setTimeout(() => {
                this.isProcessing = false;
                this.processQueue();
            }, this.interval);
        }
    }
}
const messageQueue = new MessageQueue();
export function createMessageToast(message) {
    messageQueue.addMessage(message);
}
function showMessageToast(message) {
    const toastElement = document.createElement("div");
    toastElement.classList.add("toast", ...TOAST_CLASSES);
    const icon = MESSAGE_ICONS[message.level];
    toastElement.innerHTML = `${icon} ${message.message}`;
    toastElement.style.cssText = MESSAGE_BACKGROUND_COLOR[message.level];
    document.body.appendChild(toastElement);
    // Play sound if available
    let soundId = "notification-sound"; // Default sound
    if (message.level === 40) {
        soundId = "notification-sound-error";
    }
    else if (message.level === 50) {
        soundId = "notification-sound-award";
    }
    const notificationSound = document.getElementById(soundId);
    notificationSound === null || notificationSound === void 0 ? void 0 : notificationSound.play().catch(() => { }); // Fails silently
    // Animation sequence
    requestAnimationFrame(() => {
        toastElement.classList.add("show-from-top");
        setTimeout(() => {
            toastElement.classList.remove("show-from-top");
            toastElement.classList.add("hiding");
            setTimeout(() => {
                toastElement.remove();
            }, 500); // Animation duration
        }, 5000); // Display duration
    });
}
/**
* ! Universally exclusive function to send a message to the user from JS events.
* @param {string} message - The message to send.
*/
export function sendUserMessage(level, message) {
    // * Check if the message level is valid, if not gracefully default to 'info'.
    const levels = ['debug', 'info', 'success', 'warning', 'error', 'award'];
    if (!levels.includes(level)) {
        console.error("Invalid message level, defaulting to 'info' :", level);
        level = 'info';
    }
    // Construct the URL using the template from Django
    const encodedMessage = encodeURIComponent(message);
    const post_url = window.location.origin + ENDPOINT_SEND_USER_MESSAGE
        .replace('<str:level>', level)
        .replace('<str:message>', encodedMessage);
    // Send the message to the user
    typedHTMX.ajax("POST", post_url);
}
