var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { sendUserMessage } from "../modules/notification";
// DOM Elements
const API_URL = `${window.location.origin}/brain/editor/predict/`;
const ACCEPT_API_URL = `${window.location.origin}/brain/editor/accept/`;
const PREDICTION_SUCCESS_SOUND_ID = 'prediction-success-sound';
const FRAGMENT_LUID_LENGTH = 3;
// Core types and state
let isPredictionRunning = false;
/**
 * Tweak these context sizes if needed. They control how many characters
 * before/after the cursor are sent to the prediction API.
 */
const CONTEXT_BEFORE_CURSOR = 300;
const CONTEXT_AFTER_CURSOR = 200;
/**
 * How long (ms) until predictions are auto-dismissed if user doesn’t accept
 * them by pressing Tab. Adjust as needed.
 */
const AUTO_DISMISS_TIMEOUT = 5000; // 5 seconds
let lastPredictionContext = null;
var PredictionMode;
(function (PredictionMode) {
    PredictionMode["INSERT"] = "insert";
    PredictionMode["REMOVE"] = "remove";
})(PredictionMode || (PredictionMode = {}));
var PredictionType;
(function (PredictionType) {
    PredictionType["CONTEXT_FREE"] = "context-free";
    PredictionType["CONTEXT_SENSITIVE"] = "context-sensitive";
})(PredictionType || (PredictionType = {}));
class PredictionError extends Error {
    constructor(message, context, originalError) {
        super(message);
        this.context = context;
        this.originalError = originalError;
        this.name = 'PredictionError';
    }
}
// Logging utilities
function logPredictionError(error, operation) {
    if (error instanceof PredictionError) {
        console.error(`Prediction Error during ${operation}:`, {
            message: error.message,
            context: error.context,
            originalError: error.originalError
        });
    }
    else if (error instanceof Error) {
        console.error(`Unexpected error during ${operation}:`, error);
    }
    else {
        console.error(`Unknown error during ${operation}:`, error);
    }
}
const DEBUG = process.env.NODE_ENV !== 'production';
const debugLog = (message, data) => {
    if (DEBUG)
        console.log(`[Prediction Debug] ${message}`, data || '');
};
/**
 * Ensures the prediction is valid: must have a mode, type, suggestion, and
 * if mode is REMOVE, must have a non-empty fragment.
 */
function validatePrediction(prediction) {
    var _a;
    const valid = !!(prediction &&
        prediction.mode &&
        prediction.type &&
        prediction.suggestion &&
        (prediction.mode !== PredictionMode.REMOVE || ((_a = prediction.fragment) === null || _a === void 0 ? void 0 : _a.trim())));
    if (!valid && DEBUG) {
        console.warn('Invalid prediction encountered:', prediction);
    }
    return valid;
}
// DOM Utilities
class DOMUtils {
    /**
     * Extracts a snippet of text from the editor around the cursor (based on
     * CONTEXT_BEFORE_CURSOR / CONTEXT_AFTER_CURSOR). Returns the text before
     * and after the cursor, plus the cursor offset in the full textContent.
     */
    static getContextFromEditor(editor, selection) {
        if (!(editor === null || editor === void 0 ? void 0 : editor.isContentEditable) || !(selection === null || selection === void 0 ? void 0 : selection.rangeCount)) {
            if (DEBUG)
                console.warn('No valid selection or editor not contentEditable.');
            return { beforeContext: '', afterContext: '', cursorOffset: 0 };
        }
        try {
            const range = selection.getRangeAt(0);
            const textContent = editor.textContent || '';
            const cursorOffset = this.getCursorOffset(editor, range);
            const beforeStart = Math.max(0, cursorOffset - CONTEXT_BEFORE_CURSOR);
            const afterEnd = Math.min(textContent.length, cursorOffset + CONTEXT_AFTER_CURSOR);
            return {
                beforeContext: textContent.substring(beforeStart, cursorOffset),
                afterContext: textContent.substring(cursorOffset, afterEnd),
                cursorOffset
            };
        }
        catch (error) {
            console.error('Error getting context:', error);
            return { beforeContext: '', afterContext: '', cursorOffset: 0 };
        }
    }
    /**
     * Walks through all text nodes in the editor to compute the
     * absolute cursor offset from the start of the editor.
     */
    static getCursorOffset(editor, range) {
        let offset = 0;
        const walker = document.createTreeWalker(editor, NodeFilter.SHOW_TEXT);
        let node;
        while ((node = walker.nextNode())) {
            if (node === range.startContainer) {
                return offset + range.startOffset;
            }
            offset += (node.textContent || '').length;
        }
        if (DEBUG)
            console.warn('Cursor offset not found in text nodes. Returning 0.');
        return 0;
    }
}
// UI Components
class PredictionUI {
    /**
     * Creates an inline <span> that visually represents the suggested text or
     * removal. The main difference now is that the rationale “tooltip” is
     * appended to the document body (floating overlay), not into the editor.
     */
    static createSuggestionSpan(suggestion, mode, type, offset, rationale, isLastSuggestion, fragment) {
        const container = document.createElement('span');
        container.className = 'token-prediction-container';
        container.style.position = 'relative';
        const predictionSpan = document.createElement('span');
        predictionSpan.className = `token-prediction token-prediction-${mode}`;
        predictionSpan.textContent = suggestion;
        predictionSpan.dataset.mode = mode;
        predictionSpan.dataset.type = type;
        if (mode === PredictionMode.REMOVE && fragment) {
            predictionSpan.dataset.fragment = fragment;
        }
        predictionSpan.style.cssText = `
            opacity: 0.8; display: inline; visibility: visible; white-space: pre-wrap; 
            word-wrap: break-word; position: relative; pointer-events: auto; z-index: 1000; 
            padding: 0 2px; margin: 0 1px; font-style: italic; 
            text-decoration: ${mode === PredictionMode.REMOVE ? 'line-through' : 'none'};
            color: ${mode === PredictionMode.INSERT ? '#6366f1' : '#ef4444'};
            background-color: ${mode === PredictionMode.INSERT ? 'rgba(99, 102, 241, 0.1)' : 'rgba(239, 68, 68, 0.1)'};
            border-bottom: 1px dashed ${mode === PredictionMode.INSERT ? '#6366f1' : '#ef4444'};
        `;
        // Create a tooltip appended to body so it doesn’t pollute the editor
        const tooltip = document.createElement('span');
        tooltip.className = 'token-prediction-tooltip floating-tooltip';
        tooltip.style.cssText = `
            position: absolute; background: linear-gradient(135deg, #374151 0%, #1f2937 100%);
            color: white; padding: 10px 14px; border-radius: 6px;
            font-size: 12px; line-height: 1.4; white-space: normal; max-width: 300px; 
            width: max-content; opacity: 0; transition: opacity 0.2s; pointer-events: none; z-index: 9999;
            box-shadow: 0 4px 8px rgba(0,0,0,0.2);
        `;
        const heading = document.createElement('div');
        heading.style.cssText = 'font-weight: bold; margin-bottom: 4px;';
        heading.textContent = 'Suggested Change:';
        const rationaleText = document.createElement('div');
        rationaleText.style.cssText = 'margin-top: 4px; color: #e5e7eb;';
        rationaleText.textContent = rationale || '(no rationale)';
        tooltip.append(heading, rationaleText);
        document.body.appendChild(tooltip);
        let hoverTimeout;
        // Position & show tooltip on hover
        const showTooltip = () => {
            clearTimeout(hoverTimeout);
            const rect = container.getBoundingClientRect();
            tooltip.style.opacity = '1';
            // Position the tooltip above (or below) the container
            // so it doesn’t get cut off if near the top edge
            const tooltipRect = tooltip.getBoundingClientRect();
            const topOffset = rect.top - tooltipRect.height - 6;
            tooltip.style.top = (topOffset < 0 ? rect.bottom + 6 : topOffset) + 'px';
            tooltip.style.left = (rect.left + (rect.width - tooltipRect.width) / 2) + 'px';
        };
        const hideTooltip = () => {
            hoverTimeout = window.setTimeout(() => {
                tooltip.style.opacity = '0';
            }, 100);
        };
        container.addEventListener('mouseenter', showTooltip);
        container.addEventListener('mousemove', showTooltip);
        container.addEventListener('mouseleave', hideTooltip);
        container.appendChild(predictionSpan);
        return container;
    }
    /**
     * Creates the little “[tab]” indicator shown after the last suggestion.
     */
    static createTabIndicator() {
        const tabText = document.createElement('span');
        tabText.className = 'token-prediction rainbow-text';
        tabText.textContent = '[tab]';
        tabText.style.cssText = `
            font-size: 0.9em; font-family: monospace; margin-left: 4px;
            opacity: 0; transform: translateY(20px);
            transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
            display: inline; visibility: visible; position: relative;
            pointer-events: auto; z-index: 1000; color: #10b981;
        `;
        return tabText;
    }
    /**
     * Smoothly animates the removal of a prediction element from the DOM.
     */
    static animateRemoval(element) {
        return new Promise(resolve => {
            element.style.transition = 'opacity 0.2s ease-out, transform 0.2s ease-out';
            element.style.opacity = '0';
            element.style.transform = 'translateY(5px)';
            setTimeout(() => {
                element.remove();
                resolve();
            }, 200);
        });
    }
}
// API Interaction
function fetchPredictions(blockLuid, context) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!blockLuid || !context) {
            if (DEBUG)
                console.warn('No blockLuid or context for predictions.');
            return [];
        }
        try {
            debugLog('Fetching predictions', { blockLuid, context });
            const formData = new FormData();
            formData.append('block_luid', blockLuid);
            formData.append('before_context', context.beforeContext);
            formData.append('after_context', context.afterContext);
            const response = yield fetch(API_URL, { method: 'POST', body: formData });
            if (!response.ok) {
                throw new PredictionError('API response not ok', {
                    status: response.status,
                    statusText: response.statusText
                });
            }
            const data = yield response.json();
            debugLog('Raw API response', data);
            // Validate array lengths
            const { mode, type, fragment_before_suggestion, suggestion, rationale, fragment_luids } = data;
            if (!Array.isArray(mode) ||
                !Array.isArray(type) ||
                !Array.isArray(fragment_before_suggestion) ||
                !Array.isArray(suggestion) ||
                !Array.isArray(rationale) ||
                !Array.isArray(fragment_luids) ||
                mode.length !== type.length ||
                mode.length !== suggestion.length ||
                mode.length !== rationale.length ||
                mode.length !== fragment_before_suggestion.length ||
                mode.length !== fragment_luids.length) {
                console.error('Mismatched prediction arrays from API:', data);
                return [];
            }
            // Add validation for fragment_luids
            if (!fragment_luids.every(luid => luid && luid.length === FRAGMENT_LUID_LENGTH)) {
                console.error('Invalid fragment_luids received:', fragment_luids);
                return [];
            }
            const predictions = [];
            for (let i = 0; i < mode.length; i++) {
                const fragment_luid = fragment_luids[i];
                if (!fragment_luid || fragment_luid.length !== FRAGMENT_LUID_LENGTH) {
                    console.error(`Invalid fragment_luid at index ${i}:`, fragment_luid);
                    continue;
                }
                const pred = {
                    mode: mode[i],
                    type: type[i],
                    fragment: fragment_before_suggestion[i] || '',
                    suggestion: suggestion[i],
                    rationale: rationale[i],
                    fragment_luid
                };
                if (validatePrediction(pred))
                    predictions.push(pred);
            }
            return predictions;
        }
        catch (error) {
            logPredictionError(error, 'fetch predictions');
            return [];
        }
    });
}
// Matching Helpers
function normalizeForFuzzyMatch(str) {
    return str
        .toLowerCase()
        .replace(/[.,!?'"`]/g, '')
        .replace(/\s+/g, ' ')
        .trim();
}
/**
 * Attempts to find a unique occurrence of `needle` in `haystack`.
 * First tries an exact match, then a fuzzy match.
 * Returns null if multiple matches or none found.
 */
function fuzzyFindUniqueFragment(haystack, needle) {
    if (!needle.trim())
        return null; // no need to match empty strings
    const idx = haystack.indexOf(needle);
    if (idx !== -1) {
        // Found an exact match. Check if there's a second occurrence.
        const secondIdx = haystack.indexOf(needle, idx + needle.length);
        if (secondIdx === -1) {
            return { start: idx, end: idx + needle.length };
        }
        if (DEBUG)
            console.warn(`Multiple exact occurrences of fragment '${needle}' found.`);
        return null;
    }
    // Attempt fuzzy match
    const normalizedHaystack = normalizeForFuzzyMatch(haystack);
    const normalizedNeedle = normalizeForFuzzyMatch(needle);
    const positions = [];
    let pos = normalizedHaystack.indexOf(normalizedNeedle);
    while (pos !== -1) {
        positions.push(pos);
        pos = normalizedHaystack.indexOf(normalizedNeedle, pos + normalizedNeedle.length);
    }
    if (positions.length === 1) {
        const ratio = positions[0] / normalizedHaystack.length;
        const approxStart = Math.floor(ratio * haystack.length);
        const end = Math.min(haystack.length, approxStart + needle.length);
        return { start: approxStart, end };
    }
    if (positions.length > 1 && DEBUG) {
        console.warn(`Multiple fuzzy matches of '${needle}' found.`);
    }
    return null;
}
/**
 * Returns every text node under `element`.
 */
function getAllTextNodes(element) {
    const nodes = [];
    const walker = document.createTreeWalker(element, NodeFilter.SHOW_TEXT);
    let node;
    while ((node = walker.nextNode())) {
        nodes.push(node);
    }
    return nodes;
}
/**
 * Finds the text node that contains the `targetOffset` (in characters from
 * the start of the editor). Returns that node and the offset within it.
 */
function findNodeAndOffset(nodes, targetOffset) {
    var _a, _b;
    let currentOffset = 0;
    for (const node of nodes) {
        const length = ((_a = node.textContent) === null || _a === void 0 ? void 0 : _a.length) || 0;
        if (currentOffset + length > targetOffset) {
            return { node, offset: targetOffset - currentOffset };
        }
        currentOffset += length;
    }
    if (DEBUG)
        console.warn(`No node found for targetOffset ${targetOffset}. Returning last node end.`);
    const lastNode = nodes[nodes.length - 1];
    return { node: lastNode || null, offset: ((_b = lastNode === null || lastNode === void 0 ? void 0 : lastNode.textContent) === null || _b === void 0 ? void 0 : _b.length) || 0 };
}
/**
 * Collects adjacent text nodes around `span` that might need to be updated
 * during a removal operation (i.e., the text to remove might span multiple
 * text nodes).
 */
function getAllAdjacentTextNodes(span) {
    const nodes = [];
    let sibling = span.previousSibling;
    while (sibling) {
        if (sibling.nodeType === Node.TEXT_NODE) {
            nodes.unshift(sibling);
        }
        else if (!(sibling instanceof Element) || !sibling.classList.contains('token-prediction-container')) {
            break;
        }
        sibling = sibling.previousSibling;
    }
    sibling = span.nextSibling;
    while (sibling) {
        if (sibling.nodeType === Node.TEXT_NODE) {
            nodes.push(sibling);
        }
        else if (!(sibling instanceof Element) || !sibling.classList.contains('token-prediction-container')) {
            break;
        }
        sibling = sibling.nextSibling;
    }
    return nodes;
}
/**
 * Deletes `length` characters starting from `position` across multiple text
 * nodes if needed.
 */
function updateTextNodes(nodes, position, length) {
    let remaining = length;
    for (const node of nodes) {
        const text = node.textContent || '';
        if (position < text.length) {
            const deleteCount = Math.min(remaining, text.length - position);
            node.textContent = text.slice(0, position) + text.slice(position + deleteCount);
            remaining -= deleteCount;
            if (remaining <= 0)
                break;
            position = 0;
        }
        else {
            position -= text.length;
        }
    }
}
/** Immediately removes a span from the DOM with no animation. */
function cleanupSpan(span) {
    if (!span)
        return;
    span.style.transition = 'none';
    if (span.parentNode) {
        span.remove();
    }
}
/**
 * For REMOVE mode: Delete the range of text that was predicted to be removed.
 */
function handleRemovePrediction(span, suggestion) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!(lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.editorRange)) {
            if (DEBUG)
                console.warn('No lastPredictionContext in handleRemovePrediction.');
            cleanupSpan(span);
            return;
        }
        const originalPosData = span.dataset.originalPosition;
        const lengthData = span.dataset.suggestionLength;
        if (!originalPosData || !lengthData) {
            if (DEBUG)
                console.warn('Missing position/length data for removal.');
            cleanupSpan(span);
            return;
        }
        const originalPos = parseInt(originalPosData, 10);
        const length = parseInt(lengthData, 10);
        if (isNaN(originalPos) || isNaN(length)) {
            if (DEBUG)
                console.warn('Invalid originalPos or length for removal.');
            cleanupSpan(span);
            return;
        }
        const nodes = getAllAdjacentTextNodes(span);
        if (!nodes.length && DEBUG) {
            console.warn('No adjacent text nodes found for removal.');
        }
        updateTextNodes(nodes, 0, length);
        cleanupSpan(span);
    });
}
/**
 * For INSERT mode: Insert the suggestion text into the DOM by replacing the
 * placeholder span with a text node. Also handles spacing between words.
 */
function handleInsertPrediction(span, suggestion, fragment_luid) {
    var _a, _b;
    if (!(lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.editorRange)) {
        if (DEBUG)
            console.warn('No context available for insert. Returning raw text node.');
        return document.createTextNode(suggestion);
    }
    if (!span.parentNode && DEBUG) {
        console.warn('Span has no parent, inserting text node standalone.');
    }
    const parent = span.parentNode || span;
    const trimmed = suggestion.trim();
    const prevText = (((_a = span.previousSibling) === null || _a === void 0 ? void 0 : _a.textContent) || '').replace(/\s+$/, '');
    const nextText = (((_b = span.nextSibling) === null || _b === void 0 ? void 0 : _b.textContent) || '').replace(/^\s+/, '');
    const needsLeadingSpace = /\w$/.test(prevText) && /^\w/.test(trimmed);
    const needsTrailingSpace = /\w$/.test(trimmed) && /^\w/.test(nextText);
    let finalSuggestion = trimmed;
    if (needsLeadingSpace)
        finalSuggestion = ' ' + finalSuggestion;
    if (needsTrailingSpace)
        finalSuggestion += ' ';
    // Create a span instead of a text node so we can style/track it
    const insertedSpan = document.createElement('span');
    insertedSpan.textContent = finalSuggestion;
    insertedSpan.dataset.fragmentLuid = fragment_luid;
    insertedSpan.title = 'AI';
    insertedSpan.classList.add('fragment-highlight');
    try {
        parent.replaceChild(insertedSpan, span);
    }
    catch (_c) {
        // If replace fails, fallback to insert
        parent.insertBefore(insertedSpan, span.nextSibling);
        cleanupSpan(span);
    }
    return insertedSpan;
}
/**
 * Creates inline spans for all predictions so the user can see them and press
 * Tab to accept or ignore them.
 */
function createPredictionElements(editor, predictions) {
    const operations = processPredictions(editor, predictions);
    const elements = [];
    operations.forEach(op => {
        var _a;
        const nodes = getAllTextNodes(editor);
        const { node, offset } = findNodeAndOffset(nodes, op.originalPosition);
        if (!node || offset > (((_a = node.textContent) === null || _a === void 0 ? void 0 : _a.length) || 0)) {
            if (DEBUG)
                console.warn('Could not find suitable node/offset for suggestion preview:', op);
            return;
        }
        // Build the suggestion <span>
        const span = PredictionUI.createSuggestionSpan(op.suggestion, op.mode, op.type, offset, op.rationale, false, op.fragment);
        span.dataset.originalPosition = String(op.originalPosition);
        span.dataset.suggestionLength = String(op.length);
        // Insert the span into the DOM
        const predictionRange = document.createRange();
        predictionRange.setStart(node, offset);
        predictionRange.collapse(true);
        predictionRange.insertNode(span);
        elements.push({
            span,
            offset,
            absoluteOffset: op.originalPosition,
            suggestion: op.suggestion,
            mode: op.mode,
            type: op.type,
            rationale: op.rationale,
            fragment_luid: op.fragment_luid
        });
    });
    // If we created any elements, add a "[tab]" indicator after the last one.
    if (elements.length) {
        const lastElement = elements[elements.length - 1];
        const tabIndicator = PredictionUI.createTabIndicator();
        lastElement.span.insertAdjacentElement('afterend', tabIndicator);
        requestAnimationFrame(() => {
            tabIndicator.style.opacity = '1';
            tabIndicator.style.transform = 'translateY(0)';
        });
    }
    return elements;
}
/**
 * If fragment match cannot be found, we try:
 * 1) Insert near cursor (already in code for empty fragments).
 * 2) Insert at the end of our "context window" (endOffset).
 * 3) As a last resort, insert at the very end of the editor text content.
 */
function fallbackPosition(editor, pred, contextStart, contextEnd) {
    var _a, _b;
    // 1) If insertion with empty fragment, we do that in code below
    // 2) Otherwise, try contextEnd
    const nodes = getAllTextNodes(editor);
    // We'll pick the contextEnd as a fallback insertion offset
    let fallbackOffset = Math.min(contextEnd, ((_a = editor.textContent) === null || _a === void 0 ? void 0 : _a.length) || 0);
    // If context is zero or we can't do that, try the full text length
    if (!fallbackOffset)
        fallbackOffset = ((_b = editor.textContent) === null || _b === void 0 ? void 0 : _b.length) || 0;
    // Now find node
    const { node, offset } = findNodeAndOffset(nodes, fallbackOffset);
    if (!node)
        return null;
    return {
        mode: pred.mode,
        type: pred.type,
        fragment: pred.fragment,
        suggestion: pred.suggestion,
        originalPosition: fallbackOffset,
        length: pred.suggestion.length,
        textNode: node,
        offset,
        rationale: pred.rationale,
        fragment_luid: pred.fragment_luid
    };
}
/**
 * Preprocesses raw `predictions` from the API, determining where in the
 * editor’s text each suggestion (INSERT or REMOVE) should go.
 * Returns a sorted list of `PendingOperation`s with fallback logic if needed.
 */
function processPredictions(editor, predictions) {
    const { startOffset, endOffset, text: contextText } = (lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.editorRange) || {};
    if (startOffset == null || endOffset == null || !contextText) {
        if (DEBUG)
            console.warn('No valid editorRange for processing predictions.');
        return [];
    }
    return predictions
        .filter(validatePrediction)
        .map(pred => {
        // If removing text, we need a fragment
        if (pred.mode === PredictionMode.REMOVE && (!pred.fragment || !pred.fragment.trim())) {
            if (DEBUG)
                console.warn('Remove suggestion without a valid fragment. Skipping:', pred);
            return null;
        }
        // Attempt to find fragment in context
        const fragmentMatch = fuzzyFindUniqueFragment(contextText, pred.fragment);
        if (!fragmentMatch) {
            // If it's an INSERT with empty fragment, we do the old logic:
            if (pred.mode === PredictionMode.INSERT && !pred.fragment.trim()) {
                const position = startOffset + ((lastPredictionContext === null || lastPredictionContext === void 0 ? void 0 : lastPredictionContext.context.cursorOffset) || 0);
                if (position > endOffset) {
                    // fallback at end
                    return fallbackPosition(editor, pred, startOffset, endOffset);
                }
                const nodes = getAllTextNodes(editor);
                const { node, offset } = findNodeAndOffset(nodes, position);
                if (!node)
                    return fallbackPosition(editor, pred, startOffset, endOffset);
                return {
                    mode: pred.mode,
                    type: pred.type,
                    fragment: pred.fragment,
                    suggestion: pred.suggestion,
                    originalPosition: position,
                    length: pred.suggestion.length,
                    textNode: node,
                    offset,
                    rationale: pred.rationale,
                    fragment_luid: pred.fragment_luid
                };
            }
            // Otherwise, we can't match the fragment. Use fallback insertion
            if (DEBUG)
                console.warn(`No fragment match for: ${pred.fragment}, using fallback.`, pred);
            // For removal with no match, skip entirely
            if (pred.mode === PredictionMode.REMOVE)
                return null;
            // For insertion fallback
            return fallbackPosition(editor, pred, startOffset, endOffset);
        }
        // If found match
        if (pred.mode === PredictionMode.INSERT) {
            const position = startOffset + fragmentMatch.end;
            if (position > endOffset) {
                if (DEBUG)
                    console.warn('Insert position beyond endOffset, using fallback:', pred);
                return fallbackPosition(editor, pred, startOffset, endOffset);
            }
            const nodes = getAllTextNodes(editor);
            const { node, offset } = findNodeAndOffset(nodes, position);
            if (!node) {
                if (DEBUG)
                    console.warn('No node at position, fallback insert:', pred);
                return fallbackPosition(editor, pred, startOffset, endOffset);
            }
            return {
                mode: pred.mode,
                type: pred.type,
                fragment: pred.fragment,
                suggestion: pred.suggestion,
                originalPosition: position,
                length: pred.suggestion.length,
                textNode: node,
                offset,
                rationale: pred.rationale,
                fragment_luid: pred.fragment_luid
            };
        }
        else {
            // REMOVE
            const removalStartContext = fragmentMatch.end;
            const suggestionMatch = fuzzyFindUniqueFragment(contextText.slice(removalStartContext), pred.suggestion);
            if (!suggestionMatch) {
                if (DEBUG)
                    console.warn('No suggestion match found for removal, skipping:', pred);
                return null;
            }
            const removalAbsStart = startOffset + removalStartContext + suggestionMatch.start;
            if (removalAbsStart + pred.suggestion.length > endOffset) {
                if (DEBUG)
                    console.warn('Removal beyond endOffset, skipping:', pred);
                return null;
            }
            const nodes = getAllTextNodes(editor);
            const { node, offset } = findNodeAndOffset(nodes, removalAbsStart);
            if (!node) {
                if (DEBUG)
                    console.warn('No node for removal, skipping:', pred);
                return null;
            }
            return {
                mode: pred.mode,
                type: pred.type,
                fragment: pred.fragment,
                suggestion: pred.suggestion,
                originalPosition: removalAbsStart,
                length: pred.suggestion.length,
                textNode: node,
                offset,
                rationale: pred.rationale,
                fragment_luid: pred.fragment_luid
            };
        }
    })
        .filter((op) => op !== null);
}
/**
 * Safely returns the deepest valid text node (or node) inside a container
 * for cursor positioning. If the node itself is a text node, it’s returned
 * as-is. If it’s an element, it tries to drill down its children.
 * If none found, returns the original node.
 */
function getDeepestTextNode(node) {
    let current = node;
    while (current && current.lastChild) {
        current = current.lastChild;
    }
    if (current.nodeType === Node.TEXT_NODE) {
        return current;
    }
    return node;
}
/**
 * Accepts all displayed predictions (INSERTs or REMOVEs) in the editor. It
 * also repositions the cursor near the original position if possible.
 */
export function acceptPredictions(editor, predictions, selection, event) {
    return __awaiter(this, void 0, void 0, function* () {
        var _a;
        event === null || event === void 0 ? void 0 : event.preventDefault();
        try {
            yield playSuccessSound();
            let totalWordsAdded = 0;
            let totalWordsRemoved = 0;
            // Tally how many words were added/removed
            for (const pred of predictions) {
                const words = pred.suggestion.trim().split(/\s+/).filter(w => /\w+/.test(w));
                if (pred.mode === PredictionMode.INSERT)
                    totalWordsAdded += words.length;
                else if (pred.mode === PredictionMode.REMOVE)
                    totalWordsRemoved += words.length;
            }
            // Apply all predictions
            const blockLuid = editor.getAttribute('data-luid');
            if (!blockLuid) {
                console.error('No data-luid found on editor, cannot accept predictions.');
                return;
            }
            const lastNode = yield applyPredictions(blockLuid, predictions);
            if (!lastNode) {
                console.error('Failed to apply predictions, cannot restore cursor position.');
                return;
            }
            // ensure we pick a valid text node for the cursor
            const targetNode = getDeepestTextNode(lastNode);
            const length = ((_a = targetNode.textContent) === null || _a === void 0 ? void 0 : _a.length) || 0;
            const range = document.createRange();
            range.setStart(targetNode, Math.min(length, length));
            range.collapse(true);
            selection.removeAllRanges();
            selection.addRange(range);
            // Show a quick success message
            let message = `${predictions.length} suggestion${predictions.length !== 1 ? 's' : ''} accepted`;
            if (totalWordsRemoved > 0) {
                message += `, removed ${totalWordsRemoved} word${totalWordsRemoved !== 1 ? 's' : ''}`;
            }
            if (totalWordsAdded > 0) {
                message += `, added ${totalWordsAdded} word${totalWordsAdded !== 1 ? 's' : ''}`;
            }
            sendUserMessage('success', message);
            // Cleanup
            yield clearExistingPredictions(editor);
            scheduleNextPrediction(editor);
        }
        catch (error) {
            console.error('Error accepting predictions:', error);
            yield clearExistingPredictions(editor);
        }
    });
}
/**
 * Applies all predictions to the editor by handling both insertions and deletions.
 *
 * First, it sends the accepted fragments to the server for processing.
 * Then, if successful, it applies the DOM updates for deletions and insertions.
 *
 * @param blockLuid - The unique identifier of the block being edited
 * @param predictions - Array of predictions to apply to the editor
 * @returns The last inserted node (for cursor positioning) or null if no insertions
 */
function applyPredictions(blockLuid, predictions) {
    return __awaiter(this, void 0, void 0, function* () {
        // Track fragments for server-side processing and DOM updates
        const accepted_fragments = [];
        const insertion_fragments = [];
        const deletion_fragments = [];
        // Sort predictions into appropriate arrays
        for (const { span, suggestion, fragment_luid, mode } of predictions) {
            // Make sure fragment_luid exists and meets length requirement
            if (!fragment_luid || fragment_luid.length !== FRAGMENT_LUID_LENGTH) {
                console.error('Invalid fragment_luid:', fragment_luid);
                continue;
            }
            if (mode === PredictionMode.REMOVE) {
                deletion_fragments.push([span, suggestion]);
            }
            else {
                insertion_fragments.push([span, suggestion, fragment_luid]);
                accepted_fragments.push([fragment_luid, suggestion]); // The order is correct: [fragment_luid, suggestion]
            }
        }
        // Add debug logging
        console.log('Sending accepted fragments:', accepted_fragments);
        // Send accepted fragments to server for processing
        const response = yield fetch(ACCEPT_API_URL + blockLuid, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ accepted_fragments })
        });
        if (!response.ok) {
            console.error('Server responded with', response.status);
            return null;
        }
        let lastNode = null;
        // Handle deletions first
        for (const [span, suggestion] of deletion_fragments) {
            try {
                yield handleRemovePrediction(span, suggestion);
            }
            catch (err) {
                logPredictionError(err, 'remove prediction');
            }
        }
        // Then handle insertions
        for (const [span, suggestion, fragment_luid] of insertion_fragments) {
            // handleInsertPrediction is synchronous, so we can use it directly
            const inserted = handleInsertPrediction(span, suggestion, fragment_luid);
            if (inserted)
                lastNode = inserted;
        }
        return lastNode;
    });
}
function playSuccessSound() {
    const sound = document.getElementById(PREDICTION_SUCCESS_SOUND_ID);
    return ((sound === null || sound === void 0 ? void 0 : sound.play().catch(e => {
        if (DEBUG)
            console.warn('Failed to play success sound:', e);
    })) || Promise.resolve());
}
/**
 * Removes all prediction elements from the editor. Used when predictions are
 * dismissed or after they’re accepted.
 */
function clearExistingPredictions(editor) {
    return __awaiter(this, void 0, void 0, function* () {
        const predictions = Array.from(editor.querySelectorAll('.token-prediction, .token-prediction-container, .rainbow-text'));
        if (!predictions.length && DEBUG)
            console.info('No existing predictions to clear.');
        yield Promise.all(predictions.map(el => PredictionUI.animateRemoval(el).catch(() => el.remove())));
        // Also remove any floating tooltips left in the DOM
        const floatingTooltips = Array.from(document.querySelectorAll('.token-prediction-tooltip.floating-tooltip'));
        floatingTooltips.forEach(tooltip => tooltip.remove());
    });
}
/**
 * Sets up event handlers so the user can press Tab to accept predictions,
 * or do anything else (type, click away, etc.) to dismiss them.
 * Also starts the auto-dismiss timer.
 */
function setupPredictionHandlers(editor, predictions, selection) {
    let dismissTimer = null;
    const handleKeydown = (event) => __awaiter(this, void 0, void 0, function* () {
        if (event.key === 'Tab') {
            event.preventDefault();
            // Accept predictions on Tab press
            yield acceptPredictions(editor, predictions, selection, event);
            // Clear the timer so it doesn't auto-dismiss after acceptance
            if (dismissTimer) {
                clearTimeout(dismissTimer);
                dismissTimer = null;
            }
        }
        else {
            // Dismiss on any other key
            yield clearExistingPredictions(editor);
            // Clear the timer too
            if (dismissTimer) {
                clearTimeout(dismissTimer);
                dismissTimer = null;
            }
        }
    });
    const handleCleanup = () => __awaiter(this, void 0, void 0, function* () {
        // If user clicks, blurs, scrolls, or resizes, predictions are dismissed
        yield clearExistingPredictions(editor);
        cleanup();
    });
    const cleanup = () => {
        editor.removeEventListener('keydown', handleKeydown);
        editor.removeEventListener('click', handleCleanup);
        editor.removeEventListener('blur', handleCleanup);
        window.removeEventListener('scroll', handleCleanup);
        window.removeEventListener('resize', handleCleanup);
        // Clear dismiss timer
        if (dismissTimer) {
            clearTimeout(dismissTimer);
            dismissTimer = null;
        }
    };
    editor.addEventListener('keydown', handleKeydown);
    editor.addEventListener('click', handleCleanup);
    editor.addEventListener('blur', handleCleanup);
    window.addEventListener('scroll', handleCleanup);
    window.addEventListener('resize', handleCleanup);
    // Start auto-dismiss timer upon showing predictions
    dismissTimer = window.setTimeout(() => __awaiter(this, void 0, void 0, function* () {
        // If user hasn’t pressed Tab by now, remove predictions
        yield clearExistingPredictions(editor);
        cleanup();
    }), AUTO_DISMISS_TIMEOUT);
    return cleanup;
}
/**
 * The main function to call for generating predictions.
 * Fetches context around the cursor, sends it to the API, and displays the
 * predictions in the editor if any are returned. Also sets up the Tab
 * key handler for acceptance.
 */
export function predictNextTokens(editor, blockLuid) {
    return __awaiter(this, void 0, void 0, function* () {
        // Helper to check if the editor (or a child) is currently the active element
        const isEditorActive = () => {
            const activeElement = document.activeElement;
            return activeElement === editor || editor.contains(activeElement);
        };
        if (!(editor === null || editor === void 0 ? void 0 : editor.isContentEditable) || !blockLuid || isPredictionRunning) {
            if (DEBUG)
                console.warn('Cannot predict tokens: invalid conditions.');
            return;
        }
        // Check if editor is active before starting
        if (!isEditorActive()) {
            if (DEBUG)
                console.warn('Editor not active, aborting prediction.');
            return;
        }
        isPredictionRunning = true;
        try {
            const selection = window.getSelection();
            if (!(selection === null || selection === void 0 ? void 0 : selection.rangeCount)) {
                if (DEBUG)
                    console.warn('No selection range available.');
                return;
            }
            // Clear any existing predictions
            yield clearExistingPredictions(editor);
            // Gather context from around the cursor
            const context = DOMUtils.getContextFromEditor(editor, selection);
            const editorText = editor.textContent || '';
            const contextText = context.beforeContext + context.afterContext;
            const ctxIdx = editorText.indexOf(contextText);
            if (ctxIdx === -1 && DEBUG) {
                console.warn('contextText not found in editorText. May lead to inaccurate indexing.');
            }
            const contextStart = Math.max(0, ctxIdx);
            const contextEnd = contextStart + contextText.length;
            // Check again if editor is still active before fetching predictions
            if (!isEditorActive()) {
                if (DEBUG)
                    console.warn('Editor lost focus before fetching predictions, aborting.');
                return;
            }
            // Save the context info for later use when applying suggestions
            lastPredictionContext = {
                context,
                editorRange: {
                    startOffset: contextStart,
                    endOffset: contextEnd,
                    text: contextText
                },
                cursorPosition: context.cursorOffset
            };
            // Call the API
            const predictions = yield fetchPredictions(blockLuid, context);
            // Check again if editor is still active before rendering predictions
            if (!isEditorActive()) {
                if (DEBUG)
                    console.warn('Editor lost focus before applying predictions, aborting.');
                return;
            }
            if (!predictions.length && DEBUG) {
                console.info('No predictions fetched or all invalid.');
            }
            if (predictions.length) {
                // Final check before creating prediction elements
                if (!isEditorActive()) {
                    if (DEBUG)
                        console.warn('Editor lost focus before creating prediction elements, aborting.');
                    return;
                }
                // Create the inline suggestion spans
                const elements = createPredictionElements(editor, predictions);
                // Setup the event handlers to accept or dismiss
                if (elements.length) {
                    setupPredictionHandlers(editor, elements, selection);
                }
                else if (DEBUG) {
                    console.info('No elements created from predictions.');
                }
            }
        }
        catch (error) {
            console.error('Error in predictNextTokens:', error);
        }
        finally {
            isPredictionRunning = false;
        }
    });
}
/**
 * When predictions are accepted, we optionally schedule another request
 * to get the next chunk of suggestions. This time is short (10 ms) to allow
 * DOM updates first.
 */
function scheduleNextPrediction(editor) {
    const blockLuid = editor.getAttribute('data-luid');
    if (!blockLuid && DEBUG) {
        console.warn('No data-luid on editor, cannot schedule next prediction.');
    }
    if (blockLuid) {
        setTimeout(() => predictNextTokens(editor, blockLuid), 10);
    }
}
